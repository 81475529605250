<template>
  <div>
    <sidenav
      :is-sidebar-active.sync="isSidebarActive"
      :item-edit.sync="itemEdit"
      :master-tenant-options="masterTenantOptions"
      @refetch-data="refetchData"
      :is-add.sync="isAdd"
    />

    <Breadcrumb
      :nameNav="nameNav"
      :itemFilter="itemFilter"
      :searchQuery="searchQuery"
      @itemFilterSearch="addItemFilterSearch"
    />
    <!-- Table Container Card -->
    <b-card no-body class="m-2">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="
              d-flex
              align-items-center
              justify-content-start
              mb-1 mb-md-0
              text-capitalize
            "
          >
            <label>{{ $t("table.show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("table.entries") }} </label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('table.search')"
              />
              <b-button
                variant="secondary"
                @click="isSidebarActive = true"
                v-if="$can('create_masters_accounts', 'CameraManager')"
              >
                <span class="text-nowrap text-capitalize">
                  {{
                    $t("actions.add") + " " + $tc("cm_master_account.title", 1)
                  }}
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('no_record_found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #head()="data">
          <span> {{ $tc(data.label, 1) }}</span>
        </template>

        <template #head(actions)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #cell(password)="data">
          <div
            v-clipboard:copy="`${data.value}`"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            style="cursor: pointer">
            <feather-icon
              icon="CopyIcon"
              size="16"
              class="align-middle text-body"
            />
          </div>
        </template>

        <template #cell(client_secret)="data">
          <div
            v-clipboard:copy="`${data.value}`"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            style="cursor: pointer">
            <feather-icon
              icon="CopyIcon"
              size="16"
              class="align-middle text-body"
            />
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="`reseller/${data.item.id}`">
              <feather-icon icon="UsersIcon"/>
              <span class="align-middle ml-50">Resellers</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="openEdit(data.item)"
              v-if="$can('update_masters_accounts', 'CameraManager')"
            >
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50 text-capitalize">{{
                  $t("actions.edit")
                }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="confirmDelete(data.item.id)"
              v-if="$can('delete_masters_accounts', 'CameraManager')"
            >
              <feather-icon icon="TrashIcon"/>
              <span class="align-middle ml-50 text-capitalize">{{
                  $t("actions.delete")
                }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted text-capitalize">{{
                $t("table.showing", {
                  from: dataMeta.from,
                  to: dataMeta.to,
                  of: dataMeta.of,
                })
              }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <Pagination
              :totalList="totalList"
              :currentPage="currentPage"
              @updatePagination="update"
              :perPage="perPage"
            >
            </Pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  // BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {ref} from "@vue/composition-api";
import store from "@/store";
import useList from "./useList";
import Sidenav from "./Sidenav.vue";
import {useUtils as useI18nUtils} from "@core/libs/i18n";
import Pagination from "@/components/Pagination.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";

export default {
  components: {
    Pagination,
    Breadcrumb,
    Sidenav,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  setup(_, context) {
    //start pagination
    const update = (data) => {
      currentPage.value = data;
    };
    //end pagination

    const isSidebarActive = ref(false);
    const itemEdit = ref({});
    const isAdd = ref(false);

    const openEdit = (item) => {
      isSidebarActive.value = true;
      itemEdit.value = item;
      isAdd.value = false;
    };

    // Breadcrumb

    const itemFilter = ref();
    const addItemFilter = (data) => {
      itemFilter.value = data;
    };
    const addItemFilterSearch = (data) => {
      data.forEach((element) => {
        if (nameNav.value === element.text) {
          searchQuery.value = element.searchQuery;
        }
      });
    };
    const nameNav = ref("breadcrumbs.vass.camera_manager.master_account");

    const {t} = useI18nUtils();

    const trans = {
      'messages.delete.title': t('messages.delete.title'),
      'messages.delete.body': t('messages.delete.body'),
      'actions.delete': t('actions.delete'),
      'actions.cancel': t('actions.cancel'),
    }

    const confirmDelete = function (id) {
      const self = this;
      const storeInner = store;
      this.$swal({
        title: trans["messages.delete.title"],
        text: trans["messages.delete.body"],
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: trans["actions.delete"],
        cancelButtonText: trans["actions.cancel"],
        customClass: {
          confirmButton: "btn btn-primary text-capitalize",
          cancelButton: "btn btn-outline-danger ml-1 text-capitalize",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          storeInner.dispatch("cm_master_account/delete", id).then(() => {
            self.refetchData();
          });
        }
      });
    };

    const onCopy = () => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Copiado correctamente',
          icon: 'ClipboardIcon',
          variant: 'success',
        },
      })
    };

    const onError = () => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'No se pudo copiar',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    };

    const {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,

      masterTenantOptions,
      masterTenantFilter,
    } = useList();

    const openAdd = () => {
      isAdd.value = true;
      isSidebarActive.value = true;
    };

    return {
      // Breadcrumb
      itemFilter,
      addItemFilter,
      nameNav,
      addItemFilterSearch,

      update,
      isSidebarActive,
      isAdd,
      itemEdit,
      openEdit,
      openAdd,
      confirmDelete,
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      masterTenantOptions,
      masterTenantFilter,

      onCopy,
      onError,
    };
  },
};
</script>
