<template>
  <b-sidebar
    id="edit-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @shown="loadData"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0 text-capitalize">
          {{ (isAdd ? $t("actions.add") : $t("actions.edit")) + " " + $tc("master_account.title", 1) }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('cm_master_account.name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="itemData.name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- topic -->
          <validation-provider
            #default="validationContext"
            name="topic"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('cm_master_account.topic')"
              label-for="topic"
            >
              <b-form-input
                id="topic"
                v-model="itemData.topic"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        
          <!-- User -->
          <validation-provider
            #default="validationContext"
            name="Usuario"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('cm_master_account.user')"
              label-for="user"
            >
              <b-form-input
                id="user"
                v-model="itemData.user"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Password -->
          <validation-provider
            #default="validationContext"
            name="Password"
            rules="required"
          >
            <b-form-group class="text-capitalize" :label="$t('cm_master_account.password')" label-for="password">
              <b-form-input
                id="password"
                v-model="itemData.password"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Client Id -->
          <validation-provider
            #default="validationContext"
            name="ClientId"
            rules="required"
          >
            <b-form-group  class="text-capitalize" :label="$t('cm_master_account.client_id')" label-for="client_id">
              <b-form-input
                id="client_id"
                v-model="itemData.client_id"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Client Secret -->
          <validation-provider
            #default="validationContext"
            name="ClientSecret"
            rules="required"
          >
            <b-form-group class="text-capitalize" :label="$t('cm_master_account.customer_key')" label-for="client_secret">
              <b-form-input
                id="client_secret"
                v-model="itemData.client_secret"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Master Tenant -->
          <validation-provider
            #default="validationContext"
            name="MasterTenant"
            rules="required"
          >
            <b-form-group
              label="Master Tenant"
              label-for="id_master_tenant"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_master_tenant"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="masterTenantOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="id_master_tenant"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 text-capitalize"
              type="submit"
            >
              {{$t("actions.edit")}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="text-capitalize"
            >
           {{$t("actions.cancel")}}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email, positive } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditSidebarActive",
    event: "update:is-edit-sidebar-active",
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: true,
    },
    masterTenantOptions: {
      type: Array,
      required: true,
    },
    isAdd: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      positive,
    };
  },
  setup(props, { emit }) {
    const blankData = {
      user: "",
      password: "",
      client_id: "",
      client_secret: "",
      id_master_tenant: null,
    };

    const toast = useToast();

    const itemData = ref(JSON.parse(JSON.stringify(blankData)));
    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData));
    };

    const onSubmit = () => {

      let dispatch;

      if (props.isAdd) {
        dispatch = store.dispatch("cm_master_account/add", itemData.value);
      } else {
        const req = {
          id: itemData.value.id,
          params: itemData.value,
        };

        dispatch = store.dispatch("cm_master_account/edit", req);
      }

      dispatch
        .then((response) => {
          if (response.success) {
            emit("refetch-data");
            emit("update:is-sidebar-active", false);
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetData);

    const loadData = () => {
      if (!props.isAdd) {
        itemData.value = props.itemEdit;
      }
    };

    return {
      itemData,
      onSubmit,

      refFormObserver,
      getValidationState,
      loadData,
      resetForm,
    };
  },
};
</script>