import { ref, computed, watch } from '@vue/composition-api'
// import { title } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'
import { debounce } from 'lodash'

export default function useList() {
  const refListTable = ref(null)

  const toast = useToast()

  store.dispatch('master_tenant/getAll')

  const masterTenantOptions = computed(() => store.getters['master_tenant/getSelectedItems'])
  const masterTenantFilter = ref(null)

  const tableColumns = [
    { label: 'actions.name', key: 'actions' },
    { label: 'master_tenants.title', key: 'master_tenant.name', sortable: true },
    { label: 'cm_master_account.name', key: 'name', sortable: true },
    { label: 'cm_master_account.topic', key: 'topic', sortable: true },
    { label: 'cm_master_account.user', key: 'user', sortable: true },
    { label: 'cm_master_account.password', key: 'password', sortable: true },
    { label: 'cm_master_account.client_id', key: 'client_id', sortable: true },
    { label: 'cm_master_account.customer_key', key: 'client_secret', sortable: true },
    { label: 'cm_master_account.reseller_id', key: 'reseller_id', sortable: true },
    { label: 'cm_master_account.reseller_number', key: 'resellers_count', sortable: true },
    {
      label: 'date.created_at', key: 'created_at', sortable: true, formatter: 'formatDateAssigned',
    },
    {
      label: 'date.updated_at', key: 'updated_at', sortable: true, formatter: 'formatDateAssigned',
    },
  ]

  const perPage = ref(10)
  const totalList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    }
  })

  const fetchList = (ctx, callback) => {
    store.dispatch('cm_master_account/getAll', {
      q: searchQuery.value,
      max: perPage.value,
      'page[n]': currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
    })
      .then(response => {
        callback(response.data)
        totalList.value = response.meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error obteniendo la información',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const formatDateAssigned = value => {
    const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss')
    return formattedDate
  }

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([currentPage, perPage, ], () => {
    refetchData()
  })

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,

    masterTenantOptions,
    masterTenantFilter,

    refetchData,
  }
}
